<template>
    <div class="countdown-container">
        <template v-if="hours && hours !== '00'">
            <p class="countdown hours">{{ hours }}:{{ minutes }}:</p>
            <p class="small-text">{{ seconds }}</p>
        </template>
        <template v-else>
            <p class="countdown">{{ minutes }}:{{ seconds }}</p>
        </template>
    </div>
</template>

<script>
export default {
    props: { nextAlarm: Number },
    data() {
        return {
            targetTime: null,
            hours: null,
            minutes: '00',
            seconds: '00',
            interval: null,
        }
    },
    methods: {
        startCountdown() {
            this.targetTime = new Date(this.nextAlarm)

            this.updateCountdown()

            this.interval = setInterval(() => {
                this.updateCountdown()
            }, 1000)
        },

        updateCountdown() {
            const now = new Date()
            const timeRemaining = this.targetTime - now

            if (timeRemaining <= 0) {
                clearInterval(this.interval)
                this.hours = '00'
                this.minutes = '00'
                this.seconds = '00'
                return
            }

            const totalSeconds = Math.floor(timeRemaining / 1000)
            const minutes = Math.floor(totalSeconds / 60)
            const seconds = totalSeconds % 60

            if (minutes < 60) {
                this.minutes = String(minutes).padStart(2, '0')
            } else {
                const hours = Math.floor(minutes / 60)
                const remainingMinutes = minutes % 60

                this.hours = String(hours).padStart(2, '0')
                this.minutes = String(remainingMinutes).padStart(2, '0')
            }

            this.seconds = String(seconds).padStart(2, '0')
        },
    },
    watch: {
        nextAlarm: {
            handler(newValue) {
                if (newValue) {
                    this.startCountdown()
                }
            },
            deep: true
        }
    },
    mounted() {
        if (this.nextAlarm) {
            this.startCountdown()
        }
    },

    beforeDestroy() {
        clearInterval(this.interval)
    }
}
</script>

<style lang="scss" scoped>
.countdown-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
}

p {
    padding: 0px !important;
}

.countdown {
    font-size: 16px;
    font-family: 'Avenir-Regular';

    @media (min-width: 1025px) {
        font-size: 24px;
    }
}

.hours {
    font-family: 'Avenir-Regular';
}

.small-text {
    font-size: 12px;
    margin-top: 2px;
    font-family: 'Avenir-Regular';

    @media (min-width: 1025px) {
        font-size: 20px;
    }
}
</style>
