<template>
    <div class="timers">
        <div class="top-container">
            <AppccTopDetail @orderPopup="showOrderPopup" :hasMoreOptions="false" :hasOrder="true" :showBackButton="false" />
        </div>
        <div class="timers-container">
            <SingleTimer v-for="timer in timers" :key="timer.id" :timerId="timer.id" />
        </div>

        <div class="popup-container blocking popup-small" v-if="isOrderPopupOpen">
            <div class="popup">
                <div class="list" :class="{ portrait: isApp || ['portrait'].includes($mq) }">
                    <div class="items-list" :class="{ 'mobile-landscape': isApp || ['portrait'].includes($mq) }">
                        <div class="item-list" v-for="option in sortingOptions" @click="changeOrder(option.value)" :class="[{ selected: selectedSortingOption === option.value }, { 'non-scroll': sortingOptions.length < 6 }]">
                            <span class="selector-color" :style="{ background: selectedSortingOption === option.value ? '#000' : 'transparent' }" :class="[{ 'no-color': selectedSortingOption !== option.value }]"></span>
                            <span class="selector-item">{{ option.displayText }}</span>
                        </div>
                    </div>
                    <Button bType="cancel" customClass="selector-popup" :bCallback="closePopup" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SingleTimer from '../components/domain/timers/SingleTimer.vue'
import AppccTopDetail from '../components/layout/AppccTopDetail.vue'
import Button from '../components/domain/ui/Button.vue'
import { SORT } from '@/constants'

export default {
    name: 'Timers',
    components: { SingleTimer, AppccTopDetail, Button },
    data() {
        return {
            isOrderPopupOpen: false,
            selectedSortingOption: null,
            sortingOptions: [
                {
                    displayText: this.$t('order.ascending'),
                    value: SORT.ASCENDING
                },
                {
                    displayText: this.$t('order.descending'),
                    value: SORT.DESCENDING
                },
                {
                    displayText: this.$t('order.no_order'),
                    value: SORT.NO_ORDER
                }
            ]
        }
    },
    computed: {
        timers() {
            const self = this

            const timers = this.$store.getters['timer/getTimers']

            if (!timers) {
                return
            }

            let sortedTimers

            const getComparableValue = (timer) => {
                return timer.currentPendingAlarm ? timer.currentPendingAlarm : timer.nextAlarm
            }

            if (self.selectedSortingOption == SORT.ASCENDING) {
                sortedTimers = timers.sort((a, b) => {
                    const valueA = getComparableValue(a)
                    const valueB = getComparableValue(b)
                    return valueA - valueB
                })
            } else if (self.selectedSortingOption == SORT.DESCENDING) {
                sortedTimers = timers.sort((a, b) => {
                    const valueA = getComparableValue(a)
                    const valueB = getComparableValue(b)
                    return valueB - valueA
                })
            } else {
                // no order = ordenar por timer.order
                sortedTimers = timers.sort((a, b) => {
                    if (a.order < b.order) return -1
                    if (a.order > b.order) return 1
                    return 0
                })
            }

            return sortedTimers
        },

        isApp() {
            return this.$store.getters.getIsApp
        }
    },
    methods: {
        loadTimers() {
            const self = this

            self.$overlay.loading()

            self.$store.dispatch('timer/loadTimers').then((response) => {
                self.$store.dispatch('timer/getLastTimesDone').then(() => {
                    setTimeout(()=>{
                        self.$overlay.hide()
                    },100)
                })
            })
        },
        showOrderPopup() {
            this.isOrderPopupOpen = true
            this.$store.commit('setIsOrderPopupOpen', true)
        },
        changeOrder(option) {
            this.selectedSortingOption = option
            localStorage.setItem('savedSortingOption', this.selectedSortingOption)

            this.isOrderPopupOpen = false
        },
        closePopup() {
            this.isOrderPopupOpen = false
            this.$store.commit('setIsOrderPopupOpen', false)
        },
        setSelectedSortingOption() {
            const savedSortingOption = localStorage.getItem('savedSortingOption')

            if (savedSortingOption) {
                this.selectedSortingOption = parseInt(savedSortingOption)
            } else {
                this.selectedSortingOption = SORT.NO_ORDER
            }
        }
    },
    watch: {},
    created() {
        const self = this
        self.loadTimers()
        self.setSelectedSortingOption()
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
.timers {
    padding: 8px;
    box-sizing: border-box;

    .timers-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 10px;
        width: 100%;
        max-width: 100%;

        // @media (min-width: 1025px) {
        //     display: grid;
        //     grid-template-columns: 1fr 1fr;
        // }

        @media (min-width: 696px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: 1454px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .popup-container {
        position: fixed;
    }

    .popup-container .items-list {
        padding-right: 0px !important;
        margin: 0px !important;
    }

    .popup-container.popup-small .popup {
        max-width: 350px;
    }

    .popup-container.blocking {
        z-index: 44;
    }

    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        .selector-cancel-btn {
            width: 100%;
            margin: 0px;
        }
        .item-list {
            padding: 8px;
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            width: 98%;
            margin: 8px 0;
            border: 1px solid $color-neutral-300;
            @include border-radius(4px);
            transition: all 0.3s ease-in-out;

            &.non-scroll {
                width: 100%;
            }

            &:hover {
                background-color: $color-selector;
            }

            &.selected {
                background-color: $color-neutral-200;
            }

            &.clear-value {
                color: $color-letter-selector;
                width: 100%;
                padding-right: 8px;
                font-size: 1.1em;
                padding-left: 38px;
                padding-right: 8px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow-wrap: anywhere;
            }
        }

        &.portrait {
            .clear-value {
                font-size: 0.8em;
            }
        }
        .items-list {
            margin-top: 2px;
            max-height: 280px;
            overflow-y: auto;
            padding-right: 8px;
            width: 100%;

            &.mobile-landscape {
                max-height: 205px;
                .selector-item {
                    font-size: 0.8em;
                    min-height: 1.2em;
                    color: $color-letter-selector;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    overflow-wrap: anywhere;
                }
            }
            .selector-color {
                padding: 5px;
                @include border-radius(100%);
                margin-left: 8px;

                &.no-color {
                    border: 1px solid $color-selector2;
                    position: relative;
                }
            }

            .selector-item {
                font-size: 1.1em;
                min-height: 1.2em;
                color: $color-letter-selector;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
